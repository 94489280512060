@import '../Styles/Style.scss';
@import '../Styles/Texte.scss';

/* ///////////////////DARK & LIGHT */
.light {

  .nav-list li a,
  .click,
  .h5-menu {
    @include black;
  }

  .nav-list li a:before {
    color: rgba(0, 0, 0, 0.1);
  }

  .logo-home {
    background-image: url('../Assets/Icons/Logo-Black.svg');
  }

  .projet-button {
    background-image: url('../Assets/Icons/Projet-Black.svg');
  }

  .cv-button {
    background-image: url('../Assets/Icons/Cv-Black.svg');
  }

  .contact-button {
    background-image: url('../Assets/Icons/Contact-Black.svg');
  }

  .theme {
    background-image: url('../Assets/Images/lune-black.svg');
  }

  .nav-list {
    background: rgba(255, 255, 255, 0.483);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
  }
}

.dark {

  .nav-list li a,
  .click,
  .h5-menu {
    @include white;
  }

  .nav-list li a:before {
    color: rgba(255, 255, 255, 0.1);
  }

  .logo-home {
    background-image: url('../Assets/Icons/Logo-White.svg');
  }

  .projet-button {
    background-image: url('../Assets/Icons/Projet-White.svg');
  }

  .cv-button {
    background-image: url('../Assets/Icons/Cv-White.svg');
  }

  .contact-button {
    background-image: url('../Assets/Icons/Contact-White.svg');
  }

  .theme {
    background-image: url('../Assets/Images/soleil-white.svg');
  }

  .nav-list {
    background: rgba(0, 0, 0, 0.483);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
}


/* ///////////////////NavBar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 3;
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100vw;
  align-items: center;

}

.nav-list {
  position: fixed;
  z-index: 2;
  @include center;
  flex-direction: column;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100vw;
  backdrop-filter: blur(11.9px);
  -webkit-backdrop-filter: blur(11.9px);
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  visibility: hidden;
}

.nav-list.loaded {
  visibility: visible;
}

.nav-list.show {
  transform: translateX(0);
  padding: 0;
}

.nav-list li {
  list-style: none;
  text-align: center;
}

.nav-list li a {
  @include horizon_outlined;
  text-decoration: none;
  font-size: max(2vw, 20px);
  padding: 15px 20px;
  display: inline-flex;
  font-weight: 700;
  transition: 0.5s;
}

.nav-list li a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  @include center;
  font-size: 2em;
  opacity: 0;
  font-weight: 900;
  letter-spacing: 500px;
  transition: letter-spacing 0.5s, left 0.5s;
  padding-left: 0;
}

/* /////////////////////////LOGO */
.logo-menu {
  padding-left: 4vh;
  padding-top: 1vh;
  transition: 0.3s;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  align-content: center;
  position: relative;
  grid-column: 1 / 2;
}

.logo-home,
.projet-button,
.cv-button,
.contact-button,
.theme {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 5rem;
  height: 5rem;
  margin: auto;
  transition: 0.3s;
}

.logo-home {
  grid-column: 1 / 2;
}

.click {
  @include danielbd;
  font-size: max(1.3rem, 15px);
  align-items: center;
  opacity: 0;
  transform: translateX(-100%);
  position: absolute;
  left: 0;
  width: 30vw;
}

.h5-menu {
  @include horizon;
  font-size: max(1.3rem, 10px);
  transition: 0.3s;
  align-items: center;
  opacity: 1;
  transform: translateX(0);
  padding-left: 1vw;
}

.theme-button {
  margin-right: 0;
  z-index: 3;
  display: grid;
  justify-content: end;
  padding-right: 4vh;
  padding-top: 1vh;
  grid-column: 5 / 6;
}

.projet-button,
.cv-button,
.contact-button {
  display: none;
}

/* /////////////////////////Hover */
@media (max-width: 1000px) {
  .navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 3rem;
  }

  .h5-menu {
    display: none;
  }

  .logo-menu {
    grid-template-columns: none;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .logo-home,
  .projet-button,
  .cv-button,
  .contact-button,
  .theme {
    width: 3rem;
    height: 3rem;
  }

  .theme-button {
    margin: 0;
    padding: 0;
    display: grid;
    align-items: center;
    justify-content: center;
  }

  .projet-button,
  .cv-button,
  .contact-button {
    display: grid;
    justify-content: center;
    transition: 0.3s;
  }


}

@media (min-width: 1000px) {
  .nav-list a:hover {
    @include horizon;
    padding-right: 10vw;
  }

  .logo-menu:hover .logo-home {
    transition: 0.3s;
    transform: rotate(180deg);
  }

  .logo-menu:hover .click {
    transition: 0.3s;
    grid-column: 2 / 3;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transform: translateX(0);
    padding-left: 1vw;
    transform: rotate(-5deg);
    z-index: 0;
  }

  .logo-menu:hover {
    transform: scale(1.2);
  }

  .theme:hover {
    transform: scale(1.5);
    transition: 0.3s;
  }

  .logo-menu:hover .h5-menu {
    align-items: center;
    opacity: 0;
    left: 0;
  }

  .nav-list li a:hover:before {
    content: attr(data-text);
    opacity: 1;
    left: 50%;
    letter-spacing: 10px;
    z-index: -1;
  }
}