@import '../Styles/Style.scss';

@font-face {
    font-family: "horizon";
    src: url('../Assets/Fonts/horizon.otf') format('opentype');
}

@font-face {
    font-family: "horizon_outlined";
    src: url('../Assets/Fonts/horizon_outlined.otf') format('opentype');
}

@font-face {
    font-family: "danielbd";
    src: url('../Assets/Fonts/danielbd.ttf')
}

@mixin horizon {
    font-family: 'horizon';
}

@mixin horizon_outlined {
    font-family: 'horizon_outlined';
}

@mixin danielbd {
    font-family: 'danielbd';
}

@mixin tt-chocolates {
    font-family: 'TT Chocolates', sans-serif;
}

html {
    font-size: 13px;
}

/* ///////////////////DARK & LIGHT */
.light {

    .baptiste,
    .harbuz,
    .portfolio,
    .h4-homepage a,
    .txt-presentation p {
        @include black;
        ;
    }

}

.dark {

    .baptiste,
    .harbuz,
    .portfolio,
    .h4-homepage a,
    .txt-presentation p {
        @include white;
    }
}

body {
    @include horizon;
}

.videos-hovered1 button,
.videos-hovered2 button,
.videos-hovered3 button {
    @include horizon;
    font-size: max(0.6vw, 7px);
}

.h1-project,
.h1-contact {
    font-size: max(3vw, 20px);
}

/* ///////////////////HOME PAGE */

.h4-homepage a {
    @include danielbd();
    font-size: max(1.5vw, 12px);
    text-decoration: none;
}

.h4-homepage {
    z-index: 1;
    transition: 0.3s;
}

@media (min-width: 1250px) {
    .h4-homepage:hover {
        transform: scale(1.2) rotate(-5deg);
        transition: 0.3s;
        text-decoration: none;
    }
}

.txt-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.baptiste {
    @include horizon_outlined;
}

.baptiste,
.harbuz {
    margin: 0;
    font-size: max(4vw, 30px);
    z-index: 1;
}

.portfolio {
    margin-top: 2vw;
    font-size: max(1vw, 7px);
    letter-spacing: max(3vw, 20px);
}

.portfolio-justify {
    text-align: center;
    padding-left: max(3vw, 20px);
}

/* ///////////////////CV PAGE */
/* h3,
p {
    color: #25292E;
} */

/* ///////////////////PROJET PAGE */
.bloc-txt-projets h2 {
    @include horizon_outlined;
    text-align: left;
    font-size: max(1.2vw, 15px);
}

.h6-project {
    text-align: right;
    font-size: max(0.6vw, 7px);
}

/* .bloc-txt-projets h2::after {
font-family: "horizon" !important;
transition: 0.3s;
} */
@media (max-width: 1000px) {
    /* .txt-presentation p {
        font-size: 1rem;
        font-weight: 800;
        text-align: justify;
    } */
}

@media (min-width: 1000px) {

    /* .bloc-txt-projets h2,
.bloc-txt-projets h2:hover, */
    .enigme-evadees:hover .h2-project,
    .cafe-creme:hover .h2-project,
    .garçonne:hover .h2-project,
    .enigme-evadees:hover .h6-project,
    .cafe-creme:hover .h6-project,
    .garçonne:hover .h6-project {
        @include horizon;
        transition: 0.3s;
    }

    /* .txt-presentation p {
        font-size: 0.8vw;
        font-weight: 800;
        text-align: justify;
    } */
}

/* ///////////////////CONTACT PAGE */
/* .number {
    font-size: max(0.8vw, 10px);
} */