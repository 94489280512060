@mixin center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin black {
    color: #25292E;
}

@mixin white {
    color: white;
}