@import '../Styles/Style.scss';
@import '../Styles/Texte.scss';

/* ///////////////////DARK & LIGHT */
.light {

    .h1-wait,
    .h2-project,
    .bloc-txt-projets {
        @include black;
        ;
    }

    .line {
        background-color: #25292E;
        ;
    }

    .videos-hovered1 button,
    .videos-hovered2 button,
    .videos-hovered3 button {
        border: solid #25292E;
        background-color: #ffffff00;
        @include black;
        transition: 0.3s;
    }

    .videos-hovered1 button:hover,
    .videos-hovered2 button:hover,
    .videos-hovered3 button:hover {
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
    }
}

.dark {

    .h1-wait,
    .h2-project,
    .bloc-txt-projets {
        @include white;
    }

    .line {
        background-color: white;
    }

    .videos-hovered1 button,
    .videos-hovered2 button,
    .videos-hovered3 button {
        border: solid #ffffff;
        background-color: #ffffff00;
        @include white;
        transition: 0.3s;
    }

    .videos-hovered1 button:hover,
    .videos-hovered2 button:hover,
    .videos-hovered3 button:hover {
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    }
}

.videos-hovered1 button:hover,
.videos-hovered2 button:hover,
.videos-hovered3 button:hover {
    backdrop-filter: blur(12.9px);
    -webkit-backdrop-filter: blur(12.9px);
    transform: scale(1.2);
}

/* ///////////////////MES PROJETS*/
.mesprojets-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.videos-hovered1,
.videos-hovered2,
.videos-hovered3 {
    @include center;
    flex-direction: column;
    z-index: 2;
    padding: 1rem;

}

.videos-hovered1 button,
.videos-hovered2 button,
.videos-hovered3 button {
    margin-top: 2rem;
    border-radius: 0.6rem;
    width: 23rem;
    height: 4rem;
}

.CafeCreme-Projet {
    border-radius: 0 2vw 2vw 0;
}

.enigme-evadees,
.cafe-creme,
.garçonne {
    width: 100%;
    margin: 1rem;
}

.enigme-evadees h2,
.cafe-creme h2,
.garçonne h2,
.enigme-evadees h6,
.cafe-creme h6,
.garçonne h6 {
    padding-left: 5rem;
    padding-right: 5rem;
}

@media screen and (max-width: 1000px) {

    .enigme-evadees h2,
    .cafe-creme h2,
    .garçonne h2,
    .enigme-evadees h6,
    .cafe-creme h6,
    .garçonne h6 {
        padding: 0;
    }

    .mesprojets-container {
        display: flex;
        height: 120vh;
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        width: 100vw;


    }

    .bloc-projets {
        height: auto;
    }

    .bloc-projets,
    .bloc-txt-projets,
    .mesprojets-container .bloc-txt-projets-container {
        width: 100vw;
    }

    .enigme-evadees,
    .cafe-creme,
    .garçonne {
        border: solid 1px;
        border-radius: 0.6rem;
        width: 100%;
        margin: 1rem;
    }

}

.bloc-projets {
    object-fit: cover;
    aspect-ratio: 16 / 9;
    transition: 0.3s;
    border-radius: 25%;
    @include center;
}

.cards-projets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
}

.h1-wait {
    text-align: center;
    @include danielbd;
    font-size: max(1vw, 15px);
    margin-top: 2rem;
}

.bloc-txt-projets-container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    width: 50vw;

}

.bloc-txt-projets {
    padding: 20px;
    margin: 1vw;
    text-align: center;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
}

.line {
    width: 40vw;
    height: 0.2vw;
    transition: 0.3s;
}

.enigme-evadees,
.cafe-creme,
.garçonne {
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-items: space-between;
    align-items: center;
    padding: 3vw;
    transition: 0.3s;
}

@media (min-width: 1250px) {

    .enigme-evadees:hover,
    .cafe-creme:hover,
    .garçonne:hover {
        transform: scale(1.2);
        transition: 0.3s;
    }
}