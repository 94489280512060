@import '../Styles/Style.scss';
@import '../Styles/Texte.scss';

/* ///////////////////DARK & LIGHT */
.light {

    .h1-contact,
    .contact-container a,
    .contact-click,
    .mail-container,
    .contact-item h2,
    .txt-presentation span {
        @include black;
    }

    .txt-presentation {
        border: solid #25292E;
    }

    .line {
        background-color: #25292E;
        ;
    }

    .icons {
        @include black;
    }
}

.dark {

    .h1-contact,
    .contact-container a,
    .mail-container,
    .contact-click,
    .contact-item h2,
    .txt-presentation span {
        @include white;
    }

    .txt-presentation {
        border: solid white;
    }

    .line {
        background-color: white;
    }

    .icons {
        @include white;
    }
}

.contact-container {
    @include center;
    flex-direction: column;
    gap: 1rem;
}

.contact-container section {
    text-align: center;
    z-index: 1;
    @include center;
    flex-direction: column;
}

.line {
    width: 40vw;
    min-width: 200px;
    height: 0.2vw;
    transition: 0.3s;
    margin-bottom: 2rem;
}

.contact-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contact-item {
    width: 50rem;
    display: grid;
    grid-template-columns: 2fr 4fr 2fr;
    align-items: center;
    padding: 1rem;
    text-decoration: none;
    transition: 0.3s;
    position: relative;
}

.icons {
    font-size: 2rem;
    justify-self: center;
}

.contact-item h2 {
    margin: 0;
    justify-self: center;

}

.contact-click {
    @include danielbd;
    font-size: max(1.5rem, 15px);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
}

@media (min-width: 1000px) {
    .contact-container section {
        height: 100vh;
    }

    .contact-item:hover .contact-click {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 1s, transform 5s;
        grid-column: 3 / 4;
        justify-self: center;
    }

    .contact-item:hover {
        transform: scale(1.2);
    }
}

.txt-presentation {
    border-radius: 15px;
    margin: 5rem 15rem;
    padding: 5rem;
}

.txt-presentation span {
    @include tt_chocolates;
    font-size: 1.3rem;
    font-weight: 800;
    text-align: justify;
}

@media (max-width: 1000px) {
    .contact-container section {
        margin-bottom: 8rem;
    }

    .txt-presentation {
        margin: 1rem;
        padding: 2rem;
    }

    .txt-presentation span {
        font-size: 1rem;
    }

    .contact-item {
        width: 90vw;
    }

    .contact-item h2 {
        font-size: 0.9rem;
    }
}

@media (min-width: 400px) {
    .contact-container section {
        height: 100vh;
    }
}