@import '../Styles/Style.scss';

@font-face {
    font-family: 'Archivo Black';
    font-style: normal;
    src: local('Archivo Black'), url('https://fonts.cdnfonts.com/s/15376/ArchivoBlack-Regular.woff') format('woff');
}

p,
li {
    font-family: 'TT Chocolates', sans-serif;
}

h1,
h2,
h3 {
    font-family: 'Archivo Black', sans-serif;
    text-transform: uppercase;
    text-align: center;
}

h1 {
    margin-top: 2em;
}

h3 {
    color: white;
}

ul {
    margin: 0.5em;
}

.light {
    .resume-container {
        box-shadow: 0 0 40px rgba(0, 0, 0, 1);
    }
}

.dark {
    .resume-container {
        box-shadow: 0 0 40px rgba(255, 255, 255, 0.5);
    }
}

/* ---------------------- GLOBAL RESUME */
.container {
    @include center;
    width: 100%;
    height: 100vh;
}

.resume-container {
    z-index: 1;
    display: grid;
    grid-template-columns: 38% 62%;
    aspect-ratio: 21/29.7;
}

/* ---------------------- LEFT SIDE */
.resume-part-1 {
    display: grid;
    grid-column: 1/2;
    align-items: center;
    justify-content: center;
    background-color: #F4A92C;
    height: 100%;
}

.resume-part-1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.resume-part-1 h2 {
    margin: 0 0 2rem 0
}

/* ---------------------- PROFIL */
.profil-container {
    display: grid;
    padding: 0 1rem 0 1rem;

}

.profil-container p {
    margin: 0;
    color: black;
}

.profil-container section {
    margin-top: 0.5rem;
}

.link-logo {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.link-logo .logo.linkedin {
    background-image: url('../Assets/Images/linkedin-black.svg');
}

.link-logo .logo.github {
    background-image: url('../Assets/Images/github_black.svg');
}

.link-logo .logo.portfolio {
    background-image: url('../Assets/Images/harbuz-logo-black.svg');
}

.profil-container section {}

.profil-container section div:nth-child(2) {
    margin-top: 0.12rem;
}

.link-logo .logo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 1rem;
    height: 1rem;
    margin: 0.1rem 0 0.1rem 0.2rem;
}

.logo.portfolio {
    margin: 0;
    width: 1.4rem;
    height: 1.4rem;
}

.link-logo p {
    margin-left: 0.5rem;
}

/* ---------------------- TRAINING */
.training-container {
    padding: 0 1rem;
}

.training-container div {
    margin-bottom: 1rem;
}

.training-container ul {
    font-weight: bold;
    padding-left: 2rem;
}

.training-container p {
    margin: 0;
    color: black;
}

/* ---------------------- INTEREST */
.interest-container {
    padding: 0 1rem;
}

.interest-container p {
    color: black;
    ;
}

/* ---------------------- RIGHT SIDE */
.resume-part-2 {
    display: grid;
    grid-column: 2/3;
    height: 100%;
    background-color: #0f2d42;
    color: white;
    padding: 0 1.6rem;
}

/* ---------------------- INTRO */
.intro p {
    color: white;
    text-align: center;
    font-weight: lighter;
}

.intro p:last-child {
    margin-top: 2rem;
}

/* ---------------------- SKILLS */
.skills-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.skills-container ul {
    padding: 0;
    margin: 0;
}

.skills-container div:nth-of-type(1) ul,
.skills-container li {
    padding-left: 0.5rem;
    margin-top: 0.5rem;
}

.skills-container div:nth-of-type(2) ul {
    border-left: solid;
    padding-left: 2rem;
}

/* ---------------------- SKILLS & PROJECTS */
.skills-and-projects {
    display: grid;
}

.skills-and-projects h2 {
    border-bottom: solid;
}

.skills-and-projects section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.skills-and-projects section :nth-child(5) {
    grid-column: 1/3;
}

.skills-and-projects ul {
    padding: 0;
    margin-top: 0;
}

.skills-and-projects ul:first-child {
    margin-bottom: 0.2rem;
}

.skills-and-projects div:not(:nth-last-of-type(1)) ul:nth-of-type(2) li {
    padding-left: 0.5rem;
}

.skills-and-projects div:not(:nth-last-of-type(1)) ul:nth-of-type(2) li {
    list-style-type: none;
}

.skills-and-projects div:not(:nth-last-of-type(1)) ul:first-of-type li {
    font-weight: bold;
}


/* ---------------------- EXPERIENCES */
.experience-container h2 {
    border-bottom: solid;
    padding-bottom: 1rem;
}

.experience-container h3,
.experience-container div :first-child {
    margin: 0;
    text-align: start;
}

.experience-container p {
    color: white;
    margin: 0.2rem 0;
}

.experience-container section {
    display: grid;
    grid-template-columns: 33% 67%;
    align-items: center;
    row-gap: 2em;
}

.experience-container ul {
    padding-left: 2rem;
}

.experience-container div {
    margin: 0.5rem 0;
}

@media (min-width: 600px) {
    .resume-container {
        height: 90%;
    }

    p,
    li {
        font-size: 0.8em;
    }

    h1 {
        margin-top: 2rem;
        font-size: 3em;
    }

    h2 {
        font-size: 1.1em;
    }

    h3 {
        font-size: 0.7em;
    }
}

// @media (max-width: 600px) {
//     h1 {
//         font-size: clamp(1.8em, 5vw, 5vw);
//     }

//     h2 {
//         font-size: clamp(0.8em, 1.8vw, 1.8vw);
//     }

//     h3 {
//         font-size: clamp(0.4em, 1.1vw, 1.1vw);
//     }

//     p,
//     li {
//         font-size: clamp(0.5em, 1.3vw, 1.3vw);
//     }
// }

@media (max-width: 600px) {
    h1 {
        margin-top: 1.5rem;
        font-size: clamp(1rem, 5vw, 5vw);
    }

    h2 {
        font-size: clamp(0.5em, 1.8vw, 1.8vw);
    }

    h3 {
        font-size: clamp(0.2em, 1.1vw, 1.1vw);
    }

    p,
    li {
        font-size: clamp(0.4em, 1.3vw, 1.3vw);
    }

    .resume-part-1 h2 {
        margin: 0 0 10px 0
    }

    .link-logo .logo {
        width: 0.7rem;
        height: 0.7rem;
    }

    .logo.portfolio {
        width: 1rem;
        height: 1rem;
        margin-left: 0.5px;
    }

    .resume-part-2 {
        padding: 0 1rem;
    }

    .intro p:last-child {
        margin-top: 1rem;
    }

    .skills-container div:nth-of-type(1) ul,
    .skills-container li {
        padding-left: 0.2rem;
        margin-top: 0.2rem;
    }

    .skills-container div:nth-of-type(2) ul {
        border-left: solid;
        padding-left: 1rem;
    }

    .experience-container h2 {
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 1000px) {
    .container {
        margin-bottom: 10%;
    }
}